<template>
  <b-navbar
    id="template-header"
    class="navbar default-layout-navbar p-0"
    toggleable="lg"
  >
  <!-- <button class="scrolltest" :class="{ 'scrolltest--hidden': !showNavbar }">
    <span>test</span>
  </button> -->

    <div class="horizontal-menu">
      <!-- <nav
        class=" navbar top-navbar-information d-flex justify-content-between arial computer">
        <p>64 Av. Saint-Exupery, 81 600 Gaillac, 81 600 Gaillac - 06 75 43 75 22</p>
          <p class="pointer" @click="reservation()">
            <i class="mdi mdi-calendar-multiple-check mx-4" >
              <span v-if="cartLength>0" data-v-6713abfe="" class="count-symbol bg-warning">{{ cartLength }}</span></i> 
            <span><b>MES <span class="gold-yellow">RESERVATIONS</span></b></span>
          </p>
        <span class="d-flex justify-content-between">
            <p class="pointer" @click="cart()">
              <i class="mdi mdi-cart mx-4" >
                <span v-if="cartproductLength>0" data-v-6713abfe="" class="count-symbol bg-warning">{{ cartproductLength }}</span>
              </i>
              <span >MON </span> <span class="gold-yellow">PANIER</span>
              </p>
        </span>
      </nav> -->

      <!-- 2ème Navbar -->
      <nav class="navbar top-navbar col-lg-12 col-12 p-0 bottom-navbar d-flex flex-column" style="background:#fff">
        <transition name="animated-navbar">
          <nav v-if="visible" style="width:99vw" class=" navbar top-navbar-information d-flex justify-content-between arial computer">
            <p>64 Av. Saint-Exupery, 81 600 Gaillac - 06 75 43 75 22</p>
              <p class="pointer" @click="reservation()">
                <i class="mdi mdi-calendar-multiple-check mx-4" >
                  <span v-if="cartLength>0" data-v-6713abfe="" class="count-symbol bg-warning">{{ cartLength }}</span></i> 
                <span><b>PRISE DE <span class="gold-yellow">RENDEZ-VOUS</span></b></span>
              </p>
            <span class="d-flex justify-content-between">
                <p class="pointer" @click="cart()">
                  <i class="mdi mdi-cart mx-4" >
                    <span v-if="cartproductLength>0" data-v-6713abfe="" class="count-symbol bg-warning">{{ cartproductLength }}</span>
                  </i>
                  <span >MON </span> <span class="gold-yellow">PANIER</span>
                  </p>
            </span>
          </nav>
        </transition>
        <div class="container my-2 computer">
          <div
            class="
              text-center
              navbar-brand-wrapper
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <router-link class="navbar-brand brand-logo" to="/">
              <img
                class="principal-logo"
                src="@/assets/images/logo.png"
                alt="logo"
              />
            </router-link>
            <router-link class="navbar-brand brand-logo-mini" to="/">
              <img
                class="principal-logo-mini"
                src="@/assets/images/logo-mini.png"
                alt="logo"
              />
            </router-link>
          </div>
          <!-- Computer menu version -->
          <div
            class="
              navbar-menu-wrapper
              d-flex
              align-items-center
              justify-content-center
              computer
            "
          >
            <div class="d-flex justify-content-center manual-hr">
              <router-link class="mx-3" to="/">
                <p class="no-color">Accueil</p>
              </router-link>
              <router-link class="mx-3" to="/services">
                <p class="no-color">Prestations</p>
              </router-link>
              <router-link class="mx-3" to="/boutique">
                <p class="no-color">Produits</p>
              </router-link>
              <router-link class="mx-3" to="/carte-cadeaux">
                <p class="no-color">Cartes Cadeaux</p>
              </router-link>
              <router-link class="mx-3" to="/#info">
                <p class="no-color">Infos pratiques</p>
              </router-link>
            </div>
          </div>
          <div class="d-flex justify-content-center navbar-brand-wrapper">
            <!-- profil/ déconnexion -->
            <div class="mx-2 navbar-brand brand-logo">
              <!-- si connecté -->
              <b-dropdown
                class="pt-0"
                v-if="this.$store.state.authentification.ulid"
                size="lg"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template #button-content>
                  <span v-if="$store.state.authentification.webpImage && $store.state.authentification.originalImage">
                  <picture>
                    <source class="img-lg rounded-circle mb-3"  :srcset="$store.state.authentification.webpImage" type="image/webp">
                    <img id="profil" class="" :src="$store.state.authentification.originalImage" alt="image" />
                  </picture>
                  </span>
                  
                  <img v-else id="profil" class="" src="@/assets/images/import/user.png" alt="image" />
                </template>
                <b-dropdown-item href="#" @click="profil()">
                  <span class="text-muted" >
                    {{ $store.state.authentification.pseudo }}
                  </span>
                </b-dropdown-item>

                <b-dropdown-item @click="myorders()">
                 Mes Achats
                </b-dropdown-item>
                
                <b-dropdown-item @click="myreservation()">
                 Mes réservations
                </b-dropdown-item>

                <b-dropdown-item @click="myvoucher()">
                 Mes cartes cadeaux
                </b-dropdown-item>

                <b-dropdown-item @click="logout()"
                  >Se déconnecter
                </b-dropdown-item>
              </b-dropdown>
              <!-- si non connecté -->
              <b-dropdown
                v-else
                size="lg"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template #button-content>
                  <img
                    id="profil"
                    src="@/assets/images/import/user.png"
                    alt="image"
                  />
                </template>
                <b-dropdown-item href="#" disabled
                  ><span class="text-muted"
                    >Veuillez vous connecter</span
                  ></b-dropdown-item
                >
                <b-dropdown-item to="/login">
                  Se connecter
                </b-dropdown-item>
                <b-dropdown-item to="/inscription">
                  S'inscrire
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </nav>

      <!-- NAVBAR MOBILE -->
      <nav class="navbar top-navbar col-lg-12 col-12 p-0 mobile" style="background:#fff">
        <div class="container my-2">
          <div
            class="
              text-center
              navbar-brand-wrapper
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <router-link class="navbar-brand brand-logo" to="/">
              <img
                class="principal-logo"
                src="@/assets/images/logo.png"
                alt="logo"
              />
            </router-link>
            <router-link class="navbar-brand brand-logo-mini" to="/">
              <img
                class="principal-logo-mini mobile"
                src="@/assets/images/logo-mini.png"
                alt="logo"
              />
            </router-link>
          </div>

          <!-- MOBILE -->
          <div class="mobile">
            <b-button
              class="btn btn-gradient-primary"
              v-b-toggle.my-sidebar
              bg-variant="primary"
            >
              <i class="mdi mdi-menu"></i>
            </b-button>
            <b-sidebar id="my-sidebar" title="Mon échappée belle" right shadow>
              <div class="px-3 py-2">
                <ul class="list-group list-unstyled">
                    <li class="text-left my-2">
                      <h3>
                        <i class="fa fa-home mx-2 "></i><router-link to="/">Accueil</router-link>
                      </h3>
                    </li>
                    <li class="text-left my-2">
                      <h3>
                        <i class="fa fa-cube mx-2 "></i><router-link to="/services">Prestations</router-link>
                      </h3>
                    </li>
                    <li class="text-left my-2">
                      <h3>
                        <i class="mdi mdi-shopping mx-2 "></i><router-link to="/boutique">Produits</router-link>
                      </h3>
                    </li>
                    <li class="text-left my-2">
                      <h3>
                        <i class="mdi mdi-wallet-giftcard mx-2 "></i><router-link to="/carte-cadeaux">Cartes cadeaux</router-link>
                      </h3>
                    </li>
                    <li class="text-left my-2">
                      <h3>
                        <i class="mdi mdi-contact-mail mx-2 "></i><router-link to="/#info">Infos pratiques</router-link>
                      </h3>
                    </li>
                    <li class="text-left my-2">
                      <span v-if="this.$store.state.authentification.ulid">
                        <h3>
                          <i class="fa fa-archive mx-2 my-2"></i><router-link to="/mesreservations">Mes réservations</router-link>
                        </h3>
                        <h3>
                          <i class="fa fa-archive mx-2 my-2"></i><router-link to="/commandes">Mes achats</router-link>
                        </h3>
                        <h3>
                          <i class="fa fa-archive mx-2 my-2"></i><router-link to="/mescartescadeaux">Mes cartes cadeaux</router-link>
                        </h3>
                        <h3>
                          <i class="fa fa-user-circle mx-2 my-2"></i><router-link to="/profil">Mon profil</router-link>
                        </h3>
                        <h3  @click="logout()" >
                          <i class="fa fa-power-off mx-2 "></i>Se déconnecter
                        </h3>
                      </span>
                      <span v-else>
                        <h3>
                          <i class="fa fa-user-circle mx-2 " ></i><router-link to="/login">Se connecter</router-link>
                        </h3>
                        <h3 class="mt-4">
                          <i class="fa fa-user-circle mx-2" ></i><router-link to="/inscription">S'inscrire</router-link>
                        </h3>
                      </span>
                    </li>
                </ul>
              </div>
              <hr>
              <div class="d-flex justify-content-around">
                <div class="d-flex flex-column align-items-center">
                  <p @click="reservation()">Prise de rendez-vous</p>
                  <i class="mdi mdi-calendar-multiple-check mx-4 mobile-icon" @click="reservation()"><span v-if="cartLength>0" data-v-6713abfe="" class="count-symbol bg-warning">{{ cartLength }}</span></i> 
                </div>
                <div class="d-flex flex-column align-items-center">
                  <p @click="cart()">Mon panier</p>
                  <i class="mdi mdi-cart mx-4 mobile-icon" @click="cart()"><span v-if="cartproductLength>0" data-v-6713abfe="" class="count-symbol bg-warning">{{ cartproductLength }}</span></i>
                </div>
              </div>
            </b-sidebar>
          </div>
        </div>
      </nav>
    </div>
  </b-navbar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "app-header",
  data() {
    return {
      isconnected: true,
      //perso
      showNavbar: true,
      lastScrollPosition: 0,
      visible: true
    };
  },
  computed: {
    ...mapGetters(["cartLength"]),
    ...mapGetters(["cartproductLength"]),
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({
        name:"login"
      })
    },
    toggleMobileSidebar: () => {
      document
        .querySelector(".bottom-navbar")
        .classList.toggle("header-toggled");
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => {
        return this.$route.path.indexOf(path) === 0; // current path starts with this path string
      });
    },
    handleSCroll() {
      let header = document.querySelector("body");
      if (window.scrollY > 70) {
        header.classList.add("fixed-on-scroll");
      } else {
        header.classList.remove("fixed-on-scroll");
      }
    },
    onScroll () {
    // Get the current scroll position
    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
     // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
    if (currentScrollPosition < 0) {
      return
    } 
    // Stop executing this function if the difference between
  // current scroll position and last scroll position is less than some offset
    if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
    return
  }
    // Here we determine whether we need to show or hide the navbar
    this.showNavbar = currentScrollPosition < this.lastScrollPosition
    // Set the current scroll position as the last scroll position
    this.lastScrollPosition = currentScrollPosition
  },
    reservation() {
      this.$router.push({
        name: "reservation",
      });
    },
    cart() {
      this.$router.push({
        name: "productcart",
      });
    },
    shop() {
      this.$router.push({
        name: "shop",
      });
    },
    services() {
      this.$router.push({
        name: "services",
      });
    },
    myorders(){
      this.$router.push({
        name:'myorders'
      })
    },
    profil(){
      this.$router.push({
        name:'profil'
      })
    },
    myreservation(){
      this.$router.push({
        name:'mesreservation'
      })
    },
    myvoucher(){
      this.$router.push({
        name:'myvoucher'
      })
    },
    visibility(e) {
      if(e.deltaY>0){
        this.visible=false
      }
      else{
        this.visible=true
      }
    }
  },
  created() {
    window.addEventListener("scroll", this.handleSCroll);
    window.addEventListener("scroll", this.onScroll);
  },
  mounted() {
    window.addEventListener("wheel", this.visibility)
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleSCroll);
    window.removeEventListener("scroll", this.onScroll);
  },
  watch: {
    $route() {
      document
        .querySelector(".bottom-navbar")
        .classList.remove("header-toggled");
    }
  },
};
</script>

<style scoped>
.top-navbar-information {
  padding: 0.8rem 1rem 0rem 1rem;
  background: #474747;
}
.top-navbar-information p {
  color: white;
}

.principal-logo {
  max-width: 100%;
  height: 69px !important;
  margin: auto;
  vertical-align: middle;
}
.principal-logo-mini {
  width: 100% !important;
  margin: auto;
  height: auto !important;
}
/* hr sur mesure */
.manual-hr {
  z-index: 200;
  position: relative;
  /* background: red; */
}
.manual-hr::after {
  content: "";
  background: rgb(41, 41, 41);
  position: absolute;
  bottom: 0;
  left: 1rem;
  height: 1px;
  width: 37rem;
}
.bottom-navbar {
  z-index: 1001 !important;
}

/* router-link css */
nav li a :hover,
nav li a.router-link-active,
nav li a.router-link-exact-active {
  /* background-color: indianred; */
  color: black;
  text-decoration: none;
  cursor: pointer;
}

a {
  color: #3e4b5b;
  text-decoration: none;
}

/* css pour sidebar on mobile */

/* image profil */
.brand-logo #profil {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
}
/* compteur panier et réservation */
.count-symbol{
  color:#474747;
  top: 2px;
  right: 5px;
  width: 10px;
  height: 10px;
  /* position: absolute; */
  border-radius: 100%;
  padding: 0px 5px;
  border: 2px solid #ffffff
}

::v-deep .dropdown .btn.dropdown-toggle {
  margin-top: -0.6rem
}
#nav2{
  display: none;
}

/* scroll test */
.scrolltest {
  z-index: 3000;
  position: fixed;
  top: 10%;
  left:10%
}
/* animation navbar top */
.animated-navbar-enter-active, .animated-navbar.leave-active {
  transition: opacity .5s;
}
/* .animated-navbar-enter, .animated-navbar- */
.mobile-icon{
  font-size: 2rem;
}
</style>