import Vue from 'vue'
import Router from 'vue-router'

import layout from '../layout'

import store from '../store/modules/authentification'

Vue.use(Router)
// guardMyroute Intercepter la navigation avec des redirections

function guardMyroute(to, from, next)
{
 if(store.state.token){
   next()
 }else{
   next('/login')
 }
}

export default new Router({
  linkExactActiveClass: 'active',
  // scrollBehavior: () => ({ y: 0 }),
  scrollBehavior (to) {
    if (to.hash) {
      return {
        selector : to.hash,
        behavior: 'smooth'
      }
    }else {
      return {y:0}
    }
  },
  
  mode: 'history',
  base: '/',
  routes: [
    { path: "*", component: () => import ('@/pages/errorPage/error-404') },
    {
      path: '',
      component:layout,
      // beforeEnter : guardMyroute,
      children: [
        {
          path:'',
          name: 'home',
          component: () => import ('@/pages/home'),
        }
      ]
    },
    {
      path: '',
      component:layout,
      // beforeEnter : guardMyroute,
      children: [
        {
          path:'/home-2',
          name: 'home-2',
          component: () => import ('@/pages/home-2'),
        }
      ]
    },
    {
      path: '/produit/:ulid',
      component:layout,
      // beforeEnter : guardMyroute,
      children: [
        {
          path:'',
          name: 'product',
          props:true,
          component: () => import ('@/pages/product')
        }
      ]
    },
    {
      path: '/boutique',
      component:layout,
      // beforeEnter : guardMyroute,
      children: [
        {
          path:'',
          name: 'shop',
          component: () => import ('@/pages/shop')
        }
      ]
    },
    {
      path: '/order',
      component:layout,
      beforeEnter : guardMyroute,
      children: [
        {
          path:'',
          name: 'order',
          component: () => import ('@/pages/order')
        }
      ]
    },
    {
      path: '/services',
      component:layout,
      // beforeEnter : guardMyroute,
      children: [
        {
          path:'',
          name: 'services',
          component: () => import ('@/pages/services')
        }
      ]
    },
    {
      path: '/reservation',
      component: layout,
      // beforeEnter : guardMyroute,
      children: [
        {
          path:'',
          name:'reservation',
          component: () => import ('@/pages/reservation')
        }
      ]
    },
    {
      path: '/panier',
      component: layout,
      // beforeEnter : guardMyroute,
      children: [
        {
          path:'',
          name:'cart',
          component: () => import ('@/pages/cart')
        }
      ],
    },
    {
      path: '/products/cart',
      component: layout,
      // beforeEnter : guardMyroute,
      children: [
        {
          path:'',
          name:'productcart',
          component: () => import ('@/pages/productshop')
        }
      ],
    },
    {
      path: '/carte-cadeaux',
      component: layout,
      // beforeEnter : guardMyroute,
      children: [
        {
          path:'',
          name:'giftCard',
          component: () => import ('@/pages/giftCard')
        }
      ],
    },
    {
      path: '/commandes',
      component: layout,
      // beforeEnter : guardMyroute,
      children: [
        {
          path:'',
          name:'myorders',
          component: () => import ('@/pages/myproductorders')
        }
      ],
    },
    {
      path: '/mesreservations',
      component: layout,
      // beforeEnter : guardMyroute,
      children: [
        {
          path:'',
          name:'mesreservation',
          component: () => import ('@/pages/myorders')
        }
      ],
    },
    {
      path: '/mescartescadeaux',
      component: layout,
      // beforeEnter : guardMyroute,
      children: [
        {
          path:'',
          name:'myvoucher',
          component: () => import ('@/pages/myvoucher')
        }
      ],
    },
    {
      path: '/details/:ulid',
      component: layout,
      // beforeEnter : guardMyroute,
      children: [
        {
          path:'',
          name:'ordersDetails',
          component: () => import ('@/pages/ordersDetails')
        }
      ],
    },
    {
      path: '/profil',
      component: layout,
      // beforeEnter : guardMyroute,
      children: [
        {
          path:'',
          name:'profil',
          component: () => import ('@/pages/profil')
        }
      ],
    },
    {
      path: '/test',
      component: layout,
      // beforeEnter : guardMyroute,
      children: [
        {
          path:'',
          name:'test',
          component: () => import ('@/pages/test')
        }
      ],
    },
    {
      path: '/',
      component: layout,
      // beforeEnter : guardMyroute,
      children: [
        {
          path:'',
          name:'infos',
          component: () => import ('@/pages/home')
        }
      ]
    },
    {
      path: '/encours',
      component: layout,
      // beforeEnter : guardMyroute,
      children: [
        {
          path:'',
          name:'inprogress',
          component: () => import ('@/pages/inprogress')
        }
      ],
    },
    {
      path: '/login',
      component:layout,
      children: [
        {
          path:'',
          name: 'login',
          component: () => import ('@/pages/login')
        }
      ]
    },
    {
      path: '/inscription',
      component:layout,
      children: [
        {
          path:'',
          name: 'signin',
          component: () => import ('@/pages/signin')
        }
      ]
    },
    {
      path: '/verification/:token',
      component:layout,
      children: [
        {
          path:'',
          name: 'signinConfirmation',
          component: () => import ('@/pages/signinConfirmation')
        }
      ]
    },
    {
      path: '/motdepasse',
      component:layout,
      children: [
        {
          path:'',
          name: 'forgotPassword',
          component: () => import ('@/pages/forgot-password')
        }
      ]
    },
    {
      path: '/nouveaumotdepasse/:token',
      component:layout,
      children: [
        {
          path:'',
          name: 'newPassword',
          component: () => import ('@/pages/new-password')
        }
      ]
    },
    {
      path: '/newsletter',
      component:layout,
      children: [
        {
          path:'',
          name: 'newsletter',
          component: () => import ('@/pages/newsletter')
        } 
      ]
    },
    {
      path: '/paiement-succes',
      component:layout,
      children: [
        {
          path:'',
          name: 'payment-succes',
          component: () => import ('@/pages/payment-succes')
        }
      ]
    },
    {
      path: '/paiement-erreur',
      component:layout,
      children: [
        {
          path:'',
          name: 'payment-failed',
          component: () => import ('@/pages/payment-failed')
        }
      ]
    },
    {
      path: '/erreur-400',
      component:{render(c) { return c('router-view')}},
      children: [
        {
          path:'',
          name: 'error-404',
          component: () => import ('@/pages/errorPage/error-404')
        }
      ]
    },
    {
      path: '/erreur-500',
      component:{render(c) { return c('router-view')}},
      children: [
        {
          path:'',
          name: 'error-500',
          component: () => import ('@/pages/errorPage/error-500')
        }
      ]
    }
  ]
})
