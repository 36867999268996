import axios from "axios"
const cart = {
    state: {
        services:[],
        scheduled:[]
    },
    getters: {
        cartLength(state) {
            return state.cart= state.services.length
        },
        totalDuration(state){
            let sum = 0
            for (let service of state.services){
                sum = sum + service.duration
            }
            return sum
        }
    },
    mutations: {
        // récupération des services stockés
        SET_SERVICES(state) {
            state.services =JSON.parse(localStorage.getItem('services'))
        },
        ADD_SERVICE(state, service){
            // state.cart++;
            state.services.push(service)
            service= 0
            const stringifyService = JSON.stringify(state.services)
            localStorage.setItem('services', stringifyService)
        },
        REMOVE_SERVICE_TO_CART (state, index) {
        state.services.splice(index, 1);
        // state.cart --
        const stringifyService= JSON.stringify(state.services)
        localStorage.setItem('services', stringifyService)
        },
        GET_SCHEDULED(state, scheduled){
            state.scheduled = scheduled
        }
    },
    actions: {
        //add services in cart
        add_service(context,service) {
            context.commit('ADD_SERVICE',service)
        },
        removeServiceToCart(context, index) {
            context.commit('REMOVE_SERVICE_TO_CART', index)
        },
        //get the scheduled services
        get_scheduled(context){
            axios.get('datescheduledservices')
            .then(
                respurchases => {
                    console.log("date scheduled", respurchases);
                    context.commit('GET_SCHEDULED',respurchases.data.result)
                    // this.purchases =respurchases.data.result.filter(ser => ser.product == null);
                    // this.generateTime(this.purchases)
                }
            )
            .catch(
                errpurchases=> {
                    errpurchases
                }
            )
        }
    }
}

export default cart;