<template>
  <section class="app-footer">
    <footer class="footer">
      <div class="row">
        <div class="col-md-3">
          <ul>
            <li data-aos="fade-left" data-aos-duration="1000">Conditions générales de vente</li>
            <li data-aos="fade-left" data-aos-duration="2000">Mentions légales</li>
          </ul>
          <span>
            <img data-aos="fade-left" data-aos-duration="3000" id="facebook" class="mx-4" @click="facebook()" src="@/assets/images/import/facebook_gold.png" alt="">
            <img data-aos="fade-left" data-aos-duration="1500" data-aos-easing="linear" @click="instagram()" id="instagram" src="@/assets/images/import/insta_gold.png" alt="">
          </span>
        </div>
        <div class="col-md-6 d-flex justify-content-center">
          <div class="logo" loading="lazy">
            <img id="logo-title" loading="lazy" src="@/assets/images/import/footer-logo-title.png" alt="">
            <img id="logo-text" loading="lazy" src="@/assets/images/import/footer-logo-text.png" alt="">
            <img id="logo-contour" loading="lazy" src="@/assets/images/import/footer-logo-contour.png" alt="">
            <img id="logo-bird" loading="lazy" src="@/assets/images/import/Colibri_fond_transparent.png" alt="">
          </div>
        </div>
        <div class="col-md-3 social d-flex justify-content-end">
          
        </div>
        <!-- Créé par aile'm -->
        <div class="container-fluid clearfix mt-2">
          <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © 2021 <a href="https://ailem.fr/" target="_blank">aile'm </a>. All rights reserved.</span>
          <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: 'app-footer',
  methods:{
    instagram(){
        window.location.href="https://www.instagram.com/monechappebelle81/"
    },
    facebook(){
        window.location.href="https://www.facebook.com/monechappeebelle81/"
    }
  }
}
</script>

<style scoped>
.footer {
  background: #fff;
}
.logo {
  position: relative;
  min-height: 17rem;
  left: -5rem;
}

#logo-contour{
  position: absolute;
  animation: contour ease-in-out 4s;
  animation-fill-mode: forwards;
}
#logo-title{
  position: absolute;
}
#logo-text{
  position: absolute;
  transform: rotate(0deg);
  animation: description ease-in-out 4s 
}
#logo-bird{
  position: absolute;
  width: 4rem;
  top: -1rem;
  left: 7rem;
  animation: bird 4.5s ease-in;
}

@keyframes contour {
  to{
    transform: rotate(360deg);
  }
  from{
    transform: rotate(0deg);
  }
}
@keyframes description {
  from{
    transform: rotate(50deg);
  }
  to{
    transform: rotate(0deg);
  }
}
@keyframes bird {
  from{
    opacity: 0;
    left: 10rem;
  }
  to{
    opacity: 1;
    left: 7rem;
  }
}

/* design ul et li */
.footer ul{
  font-family: swear-display, serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  list-style-type:none
}
#facebook{
  width: 16px;
  height: 33px
}
#instagram{
  width:2rem;
  height:2rem
}
/* exemple utilisant la directive scrollCard */
/* #logo-contour.before-enter {
  opacity: 1;
  transform: rotate(360deg);
  transition: all 4s ease-in-out;
}

#logo-contour.enter {
  opacity: 1;
  transform: rotate(0deg);
}*/
</style>
