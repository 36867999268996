// import axios from 'axios'
import router from '../../../router/index'
const authentification = {
    state:{
        token:null,
        ulid:null,
        pseudo:null,
        isAuthenticated: false,
        roles:'',
        originalImage:'',
        webpImage: ''
    },
    mutations:{
        authUser(state,userData){
            state.token= userData.token,
            state.ulid= userData.ulid,
            state.pseudo = userData.pseudo,
            state.roles = userData.roles,
            state.originalImage = userData.originalImage,
            state.webpImage = userData.webpImage,
            state.isAuthenticated = userData.isAuthenticated
        },
        clearAuth(state){
            state.pseudo = null,
            state.ulid = null,
            state.roles = null,
            state.isAuthenticated = false,
            state.token = null,
            state.originalImage = null,
            state.webpImage = null
        }
    },
    actions:{
        login({commit},Auth){
            const resGet = Auth.resmyinfo
            const res = Auth.reslogin
            let originalImage = ""
            let webpImage = ""

            if (resGet.data.image) {
                originalImage=resGet.data.image.content.originalImage
                webpImage=resGet.data.image.content.webpImage
            }

            commit('authUser', {
                ulid:resGet.data.ulid,
                token: res.data.token,
                pseudo: res.data.pseudo,
                roles: res.data.roles,
                originalImage:originalImage,
                webpImage: webpImage,
                isAuthenticated : true,
            }
            )
            console.log({resGet});
             localStorage.setItem('ulid', resGet.data.ulid)
             //Utiliser local storage
             localStorage.setItem('token', res.data.token)
             localStorage.setItem('pseudo', res.data.pseudo)
             //date d'expiration de local storage
             localStorage.setItem('expiresIn', res.data.expires)
             localStorage.setItem('originalImage', originalImage)
             localStorage.setItem('webpImage', webpImage)
             //redirection après l'enregistrement
            //  if(router.go(-1)){
            //      router.go(-1)
            //  }else{
            //      router.replace('/')
            //  }
             router.replace('/')

        },
        logout({commit}){
            localStorage.clear()
            commit('clearAuth')
            router.replace('/')
        },
        //Charger dans localstorage
        AutoLogin({commit}){ 
            const token = localStorage.getItem('token')
            // if (!token) {
            //     return
            // }
            const date_dexpiration = localStorage.getItem('expiresIn')
            const pseudo =localStorage.getItem('pseudo')
            const ulid =localStorage.getItem('ulid')
            const originalImage = localStorage.getItem('originalImage')
            const webpImage = localStorage.getItem('webpImage')

            const now = new Date()
            const exp = new Date(date_dexpiration)
            console.log(now <= exp)
            //time zone different de serveur 
            /*if(now <= exp){
                //vider localstorage
                localStorage.clear()
                commit('clearAuth')
                return
            }*/
            commit('authUser',{
                ulid:ulid,
                token:token,
                pseudo:pseudo,
                isAuthenticated:true,
                originalImage: originalImage,
                webpImage: webpImage
            })
        },
        //expiration 
        AutoLogout(){

        }
    },
    getters:{

    }
}

export default authentification;